import dashboard from './dashboard';
import editProfile from './editProfile';
import productUpload from './productUpload';

export default {
    productUpload,
    editProfile,
    dashboard,
    pickupOf: 'Pick-up of {{name}}',
    returnOf: 'Return of {{name}}',
    addToCalendar: 'Add to calendar',
    uploadAProduct: 'Upload a product',
    defaultFeedbackComment: 'The rental went perfectly well, would recommend to everybody! #SharingIsTheNewOwning',
    defaultFeedback: [
        'The rental went perfectly, happy to do it again! #SharingIsTheNewOwning',
        'I am very satisfied with the rental of this item. The landlord was extremely friendly and the product was exactly as described. The communication was clear and the handover smooth. Highly recommended!',
        'Everything went perfectly! The item was in excellent condition and the landlord was very helpful and accommodating. Thanks for the great service!',
        'Outstanding experience! The landlord was on time and very professional. The rented product worked flawlessly and matched the description perfectly. Happy to rent again!',
        'Very pleasant rental! The contact with the landlord was uncomplicated and friendly. The item was clean and in perfect condition. Top!',
        'I can definitely recommend this landlord. Very reliable and accommodating. The item was like new and worked flawlessly. Great service!',
        'The rental process was super easy and the landlord was very flexible with the handover. The product was top notch and exceeded my expectations. Happy to rent again!',
        'Excellent condition of the item and very friendly landlord. Everything was explained in detail and the communication was great. Highly recommended!',
        'Wonderful experience! The item was exactly as described and the landlord was very patient and helpful. The process was simple and quick. I am very satisfied!',
        'Very professional and pleasant contact. The item was in perfect condition and the handover was timely and uncomplicated. Thanks for the great service!',
        'A fantastic rental experience! The landlord was super friendly and the item worked perfectly. I was completely satisfied with everything and will definitely rent here again.',
        'Totally uncomplicated and super nice! The item was in top shape and everything worked without any problems. Happy to rent again!',
        'Super satisfied! Really cool guy, the landlord. Everything went smoothly and the item was flawless. Top experience!',
        'Super easy and relaxed. The handover was quick and the item fully met my expectations. Went really well!',
        'All chill! Great contact and the item was just as promised. Really enjoyed it and would definitely rent again!',
        'Absolutely seamless! Cool contact and the item was awesome. Everything super easy and quick. Would recommend to anyone!',
    ],
    feedbackIconFilled: 'Feedback Icon - Filled',
    feedbackIconEmpty: 'Feedback Icon - Empty',
    feedbackStep1: 'Rate your rental from 1-5 boomerangs',
    feedbackStep2: 'How was the communication?',
    feedbackStep3: 'How would you rate the delivery or pickup?',
    feedbackStep4: {
        overall: 'Overall Rating Result',
        comment: 'Text (optional)',
        typeSomething: 'Type something ...',
    },
    rate: 'Rate',
    showReceipt: 'Show Receipt',
    ergoTransactions: "Up to €15,000 insured transactions according to fainin's terms and conditions.",
    approx: 'Approx.',
    time: "O'clock",
    requestAgain: 'Request Again',
    popUpDisabled: 'Pop-ups are disabled!',
    enablePopups:
        'Please enable pop-ups in your browser settings to pay with Paypal. If you do not want to enable pop-ups you can pay with debit- or credit card or in the fainin app.',
    noFavorites: 'Currently you don’t have any favourites.',
    searchNow: 'Search now!',
    noProducts: 'Currently you don’t have any items.',
    lendNow: 'Lend items now!',
    moreFunWithListings: 'fainin is more fun with listings!',
    rememberFavorites: 'Remember items in your favourites and come back to book your rental soon.',
    rentOrUpload: 'Rent or upload an item to get started!',
    noChatsYet: 'No chats yet',
    createReqAndChat: 'Create a request and chat with people.',
    browse: 'Browse',
    share: 'Share',
    shareProduct: 'Look at this secure rental that I have found on fainin:',
    shareCommunity: 'Look at this Community on fainin:',
    sumsubError: 'Something went wrong. Please contact us if you continue to have problems!',
    sumsubDelayError: 'Unfortunately, this process is taking longer than usual... try again!',
    sumsubFallback: {
        title: 'Fallback link generated',
        info: 'Something went wrong with the identification process. Please use the following link to complete the identification process. If problems persist, please contact us.',
    },
    rentalDuration: 'Rental Duration',
    totalPayment: 'Total Payment',
    totalPayout: 'Total Payout',
    toRequest: 'To request',
    toOrder: 'To order',
    logout: 'Logout',
    period: 'Period',
    uploadNewProduct: 'Upload new product',
    requestList: {
        requester: 'Requester',
        product: 'Product',
        products: 'Products',
        days: 'Days',
        total: 'Total',
        start: 'Start',
        end: 'End',
        requested: 'Requested',
        viewDetails: 'View Details',
    },
    myProfile: {
        info: 'Info',
        badges: 'Badges',
        reviews: 'Reviews',
        onTotal: 'Total',
        showMoreReviews: 'Show More Reviews',
        myUploadLocation: 'My Upload Location',
    },
    statusType: {
        PENDING: 'Pending',
        MYPENDING: 'Pending',
        CANCELLED: 'Canceled',
        DECLINED: 'Declined',
        ACCEPTED: 'Accepted',
        EXPIRED: 'Expired',
    },
    lender: 'Lender',
    noData: 'Empty',
    createdAt: 'Created At:',
    chatWith: 'Chat with',
    your: 'Your',
    product: 'Product',
    rent: 'Rent',
    lend: 'Lend',
    greatNews:
        "Someone would like to rent your item. Accept the request and discuss all details in the chat so that everything works out. If it doesn't work, please write to the other person and decline the request.",
    showRequest: 'Show Request',
    downloadNoteOnRequest: 'Please download our app to see your orders.',
    requestAccepted: 'Request Accepted',
    downloadApp: 'Download App',
    requestReceived: 'Rental request',
    requestDeclined: 'Request Declined',
    requestCanceled: 'Request Canceled',
    canceled: 'Canceled',
    requestSent: 'Request Sent',
    requestSentMsg: "To complete the order sign up & pay through fainin's app.",
    request: {
        rentalDetails: 'Rental Details',
        pickup: 'Pickup',
        return: 'Return',
        rentalPrice: 'Rental Price',
        perDay: 'Price per Day',
        perHour: 'Price per Hour',
        daysTotal: 'Total Days',
        hoursTotal: 'Total Hours',
        fees: 'Service Fee',
        total: 'Total',
        sendMsg: 'To Chat',
        decline: 'Decline',
        accept: 'Accept',
        cancel: 'Cancel',
        noRentalRequests: 'You have no rental requests!',
        findProduct: 'Find your favorite product',
        noLendRequests: 'No requests for your products!',
        tryUploading: 'Try uploading a new exciting product.',
        upload: 'Upload',
        subTotal: 'Subtotal',
    },
    order: {
        rentalEnded: 'Rental ended',
        pay: 'Pay',
        waitPayment: 'Waiting for payment',
        status: 'Status',
        filterOptions: {
            requested: 'Requested',
            active: 'To Do',
            pending: 'Ongoing',
            processed: 'Waiting',
            completed: 'Ended',
            rented: 'Rented',
            paymentrequired: 'Payment required',
            ended: 'Ended',
            cancelled: 'Cancelled',
            paid: 'Paid',
        },
        rentalOngoing: 'Rental ongoing',
        endRental: 'End Rental',
        noRentedOrder: 'Earn money by lending',
        uploadNow: 'Upload product!',
        giveFeedback: 'Submit feedback',
        confirmDelivery: 'Confirm delivery',
        confirmReturn: 'Confirm Return',
        awaitRefund: 'Await Refund',
        verificationPending: 'Verification pending',
        discount: 'Discount by price scale',
    },
    orderRequestStateText: {
        requestReceivedEditNow: 'Request received: Reply now!',
        requestReceivedProfile: 'Request received: Complete profile',
        requestAcceptedWait: 'Request accepted: waiting for payment',
        rentalAwaits: 'Rental pending: Arrange handover?',
        ongoingRental: 'Rental ongoing: Discuss return?',
        returnStarted: 'Return starts:',
        uploadPhotosReturn: 'Upload photos & return',
        review: 'Give feedback',
        awaitReview: 'Awaiting feedback',
        requestCancelledRenter: 'Request cancelled: Contact renter',
        requestDeclinedRenter: 'Request declined: Contact renter',
        requestSentWait: 'Item requested: Waiting for response',
        requestAcceptedVerify: 'Request accepted. Verify now!',
        requestAcceptedVerifyPending: 'Request accepted. Verification pending',
        requestAcceptedPay: 'Request accepted. Pay now!',
        rentalStarted: 'Rental starts:',
        uploadPhotosRent: 'Upload photos & start renting',
        requestCancelledLender: 'Request cancelled: Contact lender',
        requestDeclinedLender: 'Request declined: Contact lender',
        awaitRefundOwner: 'Renter requested refund! Contact renter',
        awaitRefundRenter: 'You requested refund!',
    },
    orderTimeline: {
        completed: 'Completed',
        feedback: 'Feedback',
        itemReturned: 'Item returned',
        uploadPhotosReturn: 'Upload photos to document the item after the return to complete the rental.',
        return: 'Return',
        addedComment: 'Comment added! Confirm delivery to save.',
        productWithRenter: 'The item is with the renter.',
        deliveryDone: 'Pickup done',
        rentalAwaits: 'Rental is waiting',
        rentalAwaitsRenter:
            'The renter starts the rentals by uploading at least one picture of the renting item to document the condition.',
        validatingCustomer: 'Verifying customer',
        validatingCustomerHint:
            'After the verification is completed, the payment can be made. If you have any questions about this process, please feel free to contact our support.',
        arrangingPayment: 'Payment is being arranged',
        arrangingPaymentHint:
            'The payment is being arranged. Please remember that only transactions on our platform are insured. If you have any questions about this process, please feel free to contact our support.',
        paymentSuccess: 'Payment received',
        cancelled: 'Cancelled',
        requestAccepted: 'Request accepted',
        verify: 'Verify',
        verifyNow: 'Verify now',
        verificationStarted: 'Verification started',
        verificationInProcess: 'Verification in progress',
        verificationDone: 'Verification Completed',
        rentalStarted: 'Starting the Rental',
        productWithYou: 'The item is with you.',
        returnAwaitsOwner: 'The owner ends the rental by uploading the photos to document the item after the return.',
        rentalOngoing: 'Rental ongoing',
        refund: 'Refund',
    },
    verifyAccount: 'Verify',
    return: 'Return',
    uploadPhotos: 'Upload Photos',
    getRefund: 'Get Refund',
    chat: 'Chat',
    renter: 'Renter',
    requestedFrom: 'Requested From',
    payNow: 'Pay Now',
    congratsNiceChoice: 'Congratulations, you made a great choice. You can now pay and start your rental',
    fullDownloadApp: 'For a full user experience, download the fainin app.',
    back: 'Back',
    payment: {
        paymentSucceeded: 'Payment succeeded!',
        paymentProcessing: 'Your payment is processing.',
        paymentFailed: 'Your payment was not successful, please try again.',
        somethingWrong: 'Something went wrong.',
        unableAuthenticate:
            'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
        cardError: 'Your card has insufficient funds.',
        validationError: 'Validation Error',
    },
    comment: 'Comment',
    cancel: 'Cancel',
    refundReason: 'Refund reason*',
    completeProfile: 'Profile',
    checkIban: 'Your IBAN, zip code and city name must be correct!',
    edit: 'Edit Profile',
    editOnly: 'Edit',
    rented: 'Rented',
    lent: 'Lent',
    welcome: 'Welcome',
    rentals: 'Rentals',
    badges: 'Badges',
    messages: 'Messages',
    menu: {
        discover: 'Discover',
        dashboard: 'Dashboard',
        products: 'My Products',
        orders: 'Rentals',
        favorites: 'Favorites',
        chats: 'Chats',
        profile: 'Profile',
        uploadProduct: 'Upload Product',
        addressBook: 'Address Book',
        help: 'Help',
        yourOffers: 'Your offers',
    },
    blockUser: 'Block {{userName}}',
    noMsgs: 'No messages yet',
    sendAMsg: 'Send a message to {{userName}} below',
    messageplaceholder: 'Message',
    onboarding: {
        step1: {
            title: 'Item upload',
            info: 'You can easily start using fainin by uploading items in 2 minutes.',
            buttonName: 'Next',
        },
        step2: {
            title: 'Start chatting & requesting',
            info: 'Request an item to rent it out from your neighbours. After that you can Chat with the owner.',
            buttonName: 'Next',
        },
        step3: {
            title: 'Complete your Profile',
            info: 'In order to have the full experience of fainin you need to complete your profile.',
            buttonName: 'Start',
        },
        skip: {
            title: 'Skip Intro?',
            info: 'Do you really want to skip the Intro and start sharing now?',
            buttonName: 'Skip',
        },
    },
    productTable: {
        name: 'Produkt Titel',
        actions: 'Aktionen',
        description: 'Beschreibung',
        purchasePrice: 'Preis',
        createdAt: 'Datum',
    },
};
