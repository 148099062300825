<div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
    <div class="container text-center">
        <h1 class="mt-5 text-light">Datenschutzerklärung</h1>
        <span class="spacer-accent mx-auto"></span>
        <h3 class="mt-3 text-light">{{ 'content.home.sharingIsOwning' | translate }}</h3>
    </div>
</div>

<div class="container my-5">
    <div class="privacy-section">
        <h3>{{ 'Datenschutzerklärung' }}</h3>
        <p>
            Diese Datenschutzerklärung gilt für die Fainin GmbH [Handelsregisternummer: HRB 167347 beim Amtsgericht
            Hamburg] (im Folgenden „wir“, „uns“ oder „unser“) für unsere Tätigkeiten in Deutschland. Sie beschreibt, wie
            wir personenbezogene Daten erheben, verarbeiten und schützen und wie wir die Anforderungen der
            EU-Datenschutz-Grundverordnung (DSGVO) einhalten. Diese Erklärung gilt für alle unsere Apps, Websites,
            Produkte und Dienste, soweit nicht anders angegeben.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>1. {{ 'Zweck der Verarbeitung personenbezogener Daten' }}</h3>
        <p>
            Wir erheben und verarbeiten personenbezogene Daten, um unsere Dienste bereitzustellen, Anfragen zu
            beantworten und gesetzlichen Verpflichtungen nachzukommen. Ihre Daten werden nur in dem Umfang verarbeitet,
            der für die definierten Zwecke erforderlich ist (Art. 5 DSGVO).
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>2. {{ 'Kategorien personenbezogener Daten und Verarbeitungszwecke' }}</h3>
        <h4>2.1 Zahlungsabwicklung</h4>
        <p>Wir nutzen folgende Anbieter zur Zahlungsabwicklung:</p>
        <ul>
            <li>PayPal</li>
            <li>Stripe</li>
        </ul>
        <p>Rechtsgrundlage: Durchführung von Verträgen (Art. 6 Abs. 1 lit. b DSGVO).</p>

        <h4>2.2 Analyse und Optimierung</h4>
        <p>Zur Analyse der Nutzung und Optimierung unserer Angebote setzen wir folgende Tools ein:</p>
        <ul>
            <li>Google Analytics</li>
            <li>Hotjar</li>
            <li>Microsoft Clarity</li>
            <li>Facebook Pixel</li>
            <li>TikTok Pixel</li>
        </ul>
        <p>
            Rechtsgrundlage: Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ihre Einwilligung können Sie jederzeit
            widerrufen.
        </p>

        <h4>2.3 Kommunikation</h4>
        <p>Für die Kommunikation mit Nutzern verwenden wir:</p>
        <ul>
            <li>Amazon (SMS)</li>
            <li>Mailchimp</li>
            <li>Expo</li>
            <li>TalkJS</li>
            <li>HubSpot</li>
        </ul>
        <p>
            Rechtsgrundlage: Erfüllung vertraglicher oder vorvertraglicher Pflichten (Art. 6 Abs. 1 lit. b DSGVO) oder
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
        </p>

        <h4>2.4 Identitätsprüfung</h4>
        <p>Zur Identitätsverifizierung setzen wir folgende Dienste ein:</p>
        <ul>
            <li>Schufa</li>
            <li>SumSub</li>
        </ul>
        <p>
            Rechtsgrundlage: Erfüllung rechtlicher Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO) oder berechtigtes
            Interesse (Art. 6 Abs. 1 lit. f DSGVO).
        </p>

        <h4>2.5 Hosting und Infrastruktur</h4>
        <p>Unsere Daten werden auf folgenden Plattformen gehostet:</p>
        <ul>
            <li>Microsoft Azure</li>
            <li>Amazon S3</li>
        </ul>
        <p>Rechtsgrundlage: Erfüllung vertraglicher Pflichten (Art. 6 Abs. 1 lit. b DSGVO).</p>

        <h4>2.6 Weitere Dienste</h4>
        <p>Zusätzlich nutzen wir folgende Dienste:</p>
        <ul>
            <li>Stellate</li>
            <li>Sentry</li>
            <li>Discord</li>
            <li>OpenAI</li>
            <li>Apple/Google (Sign-ins)</li>
        </ul>
        <p>
            Rechtsgrundlage: Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO) oder Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO).
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>3. {{ 'Ihre Rechte' }}</h3>
        <p>Sie haben gemäß der DSGVO folgende Rechte:</p>
        <ul>
            <li>Auskunft über gespeicherte Daten (Art. 15 DSGVO).</li>
            <li>Berichtigung unrichtiger Daten (Art. 16 DSGVO).</li>
            <li>Löschung Ihrer Daten (Art. 17 DSGVO), soweit keine gesetzlichen Aufbewahrungspflichten bestehen.</li>
            <li>Einschränkung der Verarbeitung (Art. 18 DSGVO).</li>
            <li>Datenübertragbarkeit (Art. 20 DSGVO).</li>
            <li>Widerspruch gegen die Verarbeitung (Art. 21 DSGVO).</li>
            <li>Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO).</li>
        </ul>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>4. {{ 'Weitere Informationen nach Art. 13 Abs. 2 DSGVO' }}</h3>
        <h4>4.1 Dauer der Speicherung</h4>
        <p>
            Personenbezogene Daten werden nur so lange gespeichert, wie es für die genannten Zwecke erforderlich ist
            oder gesetzliche Aufbewahrungspflichten bestehen.
        </p>

        <h4>4.2 Weitergabe an Dritte</h4>
        <p>Daten werden an folgende Dritte weitergegeben:</p>
        <ul>
            <li>Zahlungsdienstleister (PayPal, Stripe)</li>
            <li>Analyseanbieter (Google Analytics, Hotjar, Facebook/TikTok Pixel)</li>
            <li>Kommunikationsplattformen (Amazon, Mailchimp, etc.)</li>
            <li>Hosting- und Infrastruktur-Anbieter (Microsoft Azure, Amazon S3)</li>
            <li>Identitätsprüfungsdienste (Schufa, SumSub)</li>
        </ul>

        <h4>4.3 Datenübermittlung in Drittstaaten</h4>
        <p>
            Falls Daten in Drittstaaten übermittelt werden, erfolgt dies auf Basis von Standardvertragsklauseln oder
            ähnlichen Garantien gemäß Art. 46 DSGVO.
        </p>

        <h4>4.4 Pflicht zur Bereitstellung personenbezogener Daten</h4>
        <p>
            Die Bereitstellung bestimmter Daten ist für die Nutzung unserer Dienste erforderlich. Ohne diese können wir
            die angefragten Leistungen nicht erbringen.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>5. {{ 'Datenschutz und Sicherheit' }}</h3>
        <p>
            Alle Daten werden verschlüsselt übertragen und in sicheren Rechenzentren gespeichert.<br />
            Wir setzen technische und organisatorische Maßnahmen ein, um Ihre Daten zu schützen.<br />
            Sie sind verantwortlich für die sichere Verwahrung Ihrer Zugangsdaten.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>6. {{ 'Links zu Drittanbietern' }}</h3>
        <p>
            Unsere Websites oder Apps können Links zu externen Anbietern enthalten. Wir übernehmen keine Verantwortung
            für deren Datenschutzpraktiken.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>7. {{ 'Sensible Daten' }}</h3>
        <p>
            Falls Sie uns sensible Daten übermitteln (z. B. zu politischen Meinungen, religiösen Überzeugungen oder
            Gesundheitsdaten), verwenden wir diese nur auf Basis Ihrer ausdrücklichen Einwilligung (Art. 9 Abs. 2 lit. a
            DSGVO). Ohne Ihre Einwilligung erfolgt keine Verarbeitung.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>8. {{ 'Veröffentlichung Ihrer Daten' }}</h3>
        <p>
            Personenbezogene Daten können innerhalb der Firmengruppe, einschließlich Tochtergesellschaften und
            Muttergesellschaft, weitergegeben werden. Eine Weitergabe an Dritte erfolgt nur, wenn:
        </p>
        <ul>
            <li>es gesetzlich erforderlich ist (Art. 6 Abs. 1 lit. c DSGVO),</li>
            <li>es zur Erfüllung eines Vertrags notwendig ist (Art. 6 Abs. 1 lit. b DSGVO),</li>
            <li>die Weitergabe auf Ihrer Einwilligung basiert (Art. 6 Abs. 1 lit. a DSGVO), oder</li>
            <li>berechtigte Interessen unsererseits oder eines Dritten überwiegen (Art. 6 Abs. 1 lit. f DSGVO).</li>
        </ul>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>9. {{ 'Öffentliche Daten' }}</h3>
        <p>
            Beiträge oder Bewertungen, die Sie auf unseren Plattformen hinterlassen, können öffentlich zugänglich sein.
            Bitte beachten Sie dies bei der Angabe personenbezogener Daten.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>10. {{ 'Kinder' }}</h3>
        <p>
            Unsere Dienste richten sich nicht an Personen unter 18 Jahren. Wir erheben keine personenbezogenen Daten von
            Kindern, es sei denn, wir haben die ausdrückliche Einwilligung eines Erziehungsberechtigten (Art. 8 DSGVO).
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>11. {{ 'Änderungen der Datenschutzerklärung' }}</h3>
        <p>
            Wir behalten uns das Recht vor, diese Datenschutzerklärung zu aktualisieren. Wesentliche Änderungen werden
            wir Ihnen rechtzeitig bekannt geben. Bitte überprüfen Sie regelmäßig unsere Datenschutzerklärung.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>12. {{ 'Fortbestehen der Datenschutzerklärung' }}</h3>
        <p>
            Diese Datenschutzerklärung besteht über eine Kündigung dieser Dienste oder eine Veräußerung des Unternehmens
            durch uns fort. Ihre Informationen können an potenzielle Käufer weitergegeben werden und Teil der
            veräußerten Vermögenswerte sein. In diesem Fall besteht diese Datenschutzerklärung fort.
        </p>
    </div>
    <hr />

    <div class="privacy-section">
        <h3>13. {{ 'Kontakt' }}</h3>
        <p>Bei Fragen oder zur Ausübung Ihrer Rechte wenden Sie sich an uns:</p>
        <p>
            Fainin GmbH<br />
            Mühlenkamp 4, 22303 Hamburg, Deutschland<br />
            E-Mail: contact@fainin.com
        </p>
    </div>

    <div class="privacy-section">
        <p>Zuletzt bearbeitet: 16.12.2024</p>
    </div>
</div>
